const HomeSlider = () => {
	return (
		// <!-- Wrapper -->
		<div className='wrapper'>
			{/* <!-- Section Half Slider --> */}
			<div
				className='section half-slider'
				data-dispimgpath='assets/images/clouds.jpg'
			>
				<div className='swiper-container'>
					<div className='swiper-wrapper'>
						<div className='swiper-slide'>
							{/* <!-- image --> */}
							<div className='slide'>
								<img
									src='assets/images/started-n12-1000x1080.jpg'
									className='half-slide-item__image'
									alt=''
								/>
							</div>

							{/* <!-- slide titles --> */}
							<div className='slide-titles'>
								<div className='titles'>
									{/* <div
										className='label splitting-text-anim-1'
										data-splitting
									>
										photo
									</div> */}
									<h1 className='title'>
										<a href='/#/articles'>
											<span
												className='title-inner splitting-text-anim-2'
												data-splitting
											>
												{/* Acclaimed <br /> */}
												Author
											</span>
										</a>
										<div className='col-12'>
											<div
												style={{
													backgroundImage: 'url(assets/images/pattern.png)',
													height: '10px',
													backgroundSize: 'cover',
													width: '300px',
													color: 'rgba(255, 255, 255, 0)', // 50% transparency
												}}
											>
												© 2024 Karsten Noko. All rights reserved.
											</div>
										</div>
									</h1>

									<p className='text scrolla-element-anim-1'>
										Karsten Noko combines his frontline experience with powerful storytelling, offering deep insights
										into global humanitarian challenges.
									</p>
								</div>
								<div className='view-btn'>
									<a
										href='/#/articles'
										data-splitting
										className='splitting-text-anim-1'
									>
										view articles
									</a>
								</div>
							</div>
						</div>

						<div className='swiper-slide'>
							{/* <!-- image --> */}
							<div className='slide'>
								<img
									src='assets/images/started-n19-1080x1080.jpg'
									className='half-slide-item__image'
									alt=''
								/>
							</div>

							{/* <!-- slide titles --> */}
							<div className='slide-titles'>
								<div className='titles'>
									<div
										className='label splitting-text-anim-1'
										data-splitting
									>
										{/* Two Decades of Impact As A */}
									</div>
									<h1 className='title'>
										<a href='/#/articles'>
											<span
												className='title-inner splitting-text-anim-2'
												data-splitting
											>
												Global <br />
												Humanitarian Leader
											</span>
										</a>
										<div className='col-12'>
											<div
												style={{
													backgroundImage: 'url(assets/images/pattern.png)',
													height: '10px',
													backgroundSize: 'cover',
													width: '300px',
													color: 'rgba(255, 255, 255, 0)', // 50% transparency
												}}
											>
												© 2024 Karsten Noko. All rights reserved.
											</div>
										</div>
									</h1>
									<p className='text scrolla-element-anim-1'>
										From Southern Africa to Afghanistan, Karsten Noko's experience spans continents and sectors.
									</p>
								</div>
								<div className='view-btn'>
									<a
										href='/#/articles'
										data-splitting
										className='splitting-text-anim-1'
									>
										view articles
									</a>
								</div>
							</div>
						</div>

						<div className='swiper-slide'>
							{/* <!-- image --> */}
							<div className='slide'>
								<img
									src='assets/images/started-n2_4-1080x1080.jpg'
									className='half-slide-item__image'
									alt=''
								/>
							</div>

							{/* <!-- slide titles --> */}
							<div className='slide-titles'>
								<div className='titles'>
									<div
										className='label splitting-text-anim-1'
										data-splitting
									>
										{/* Champion of */}
									</div>
									<h1 className='title'>
										<a href='/#/articles'>
											<span
												className='title-inner splitting-text-anim-2'
												data-splitting
											>
												Food Security, Healthcare, <br /> and Humanitarian Governance
											</span>
										</a>
										<div className='col-12'>
											<div
												style={{
													backgroundImage: 'url(assets/images/pattern.png)',
													height: '10px',
													backgroundSize: 'cover',
													width: '300px',
													color: 'rgba(255, 255, 255, 0)', // 50% transparency
												}}
											>
												© 2024 Karsten Noko. All rights reserved.
											</div>
										</div>
									</h1>
									<p className='text scrolla-element-anim-1'>
										Karsten Noko has been involved and led programs that save lives in the world's most challenging
										environments.
									</p>
								</div>
								<div className='view-btn'>
									<a
										href='/#/articles'
										data-splitting
										className='splitting-text-anim-1'
									>
										view articles
									</a>
								</div>
							</div>
						</div>

						<div className='swiper-slide'>
							{/* <!-- image --> */}
							<div className='slide'>
								<img
									src='assets/images/started-n15-1080x1080.jpg'
									className='half-slide-item__image'
									alt=''
								/>
							</div>

							{/* <!-- slide titles --> */}
							<div className='slide-titles'>
								<div className='titles'>
									<div
										className='label splitting-text-anim-1'
										data-splitting
									>
										{/* Voices of Experience */}
									</div>
									<h1 className='title'>
										<a href='/#/articles'>
											<span
												className='title-inner splitting-text-anim-2'
												data-splitting
											>
												On International <br />
												Humanitarian Platforms
											</span>
										</a>
										<div className='col-12'>
											<div
												style={{
													backgroundImage: 'url(assets/images/pattern.png)',
													height: '10px',
													backgroundSize: 'cover',
													width: '300px',
													color: 'rgba(255, 255, 255, 0)', // 50% transparency
												}}
											>
												© 2024 Karsten Noko. All rights reserved.
											</div>
										</div>
									</h1>
									<p className='text scrolla-element-anim-1'>
										Karsten has been featured on global platforms, sharing insights from the frontlines.
									</p>
								</div>
								<div className='view-btn'>
									<a
										href='/#/articles'
										data-splitting
										className='splitting-text-anim-1'
									>
										view articles
									</a>
								</div>
							</div>
						</div>

						{/* <div className='swiper-slide'>
							
							<div className='slide'>
								<img
									src='assets/images/started-n14_1-1000x1080.jpg'
									className='half-slide-item__image'
									alt=''
								/>
							</div>

							
							<div className='slide-titles'>
								<div className='titles'>
									<div
										className='label splitting-text-anim-1'
										data-splitting
									>
										art
									</div>
									<h1 className='title'>
										<a href='/#/articles'>
											<span
												className='title-inner splitting-text-anim-2'
												data-splitting
											>
												Negative space
											</span>
										</a>
									</div>
									<p className='text scrolla-element-anim-1'>
										It is a long established fact that a reader will be distracted by the readable content of a page
										when looking at its layout.
									</div>
								</div>
								<div className='view-btn'>
									<a
										href='/#/articles'
										data-splitting
										className='splitting-text-anim-1'
									>
										view articles
									</a>
								</div>
							</div>
						</div> */}
					</div>

					{/* <!-- pagination --> */}
					<div
						className='swiper-pagination scrolla-element-anim-1 scroll-animate'
						data-animate='active'
					></div>

					{/* <!-- navigation --> */}
					<div className='swiper-buttons'>
						<div
							className='swiper-button-prev scene-nav scene-nav--prev scrolla-element-anim-1 scroll-animate'
							data-animate='active'
						></div>
						<div
							className='swiper-button-next scene-nav scene-nav--next scrolla-element-anim-1 scroll-animate'
							data-animate='active'
						></div>
					</div>
				</div>

				{/* <!-- slides --> */}
				<div className='canvas half-slider-items__image'></div>
			</div>
		</div>
	);
};

export default HomeSlider;
