import { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import articles from '../data/articles.json';

const ArticleComp = () => {
	const [toShow, setToShow] = useState({});
	const [loaded, setLoaded] = useState(false);

	const location = useLocation();

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const id = query.get('article');
		console.log(id);

		setToShow(articles[id]);
		// console.log(toShow);
		// console.log(query);
		// console.log(articles);

		setLoaded(true);
	}, [location.search]);

	// console.log(toShow);
	return (
		// <!-- Wrapper -->
		loaded ? (
			<div className='wrapper'>
				<div className='section section-inner started-heading'>
					<div className='container'>
						<div className='row'>
							<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
								{/* <!-- titles --> */}
								<div className='m-titles'>
									<div
										className='m-category splitting-text-anim-1 scroll-animate'
										data-splitting='chars'
										data-animate='active'
									>
										Published:
										<span> {toShow.publication_date}</span>
									</div>
									<div
										className='m-category splitting-text-anim-1 scroll-animate'
										data-splitting='chars'
										data-animate='active'
									>
										<a
											href={toShow.url}
											target='blank'
										>
											View source
										</a>
									</div>
									<div
										className='m-title splitting-text-anim-1 scroll-animate'
										data-splitting='chars'
										data-animate='active'
									>
										{toShow.title}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Section Image Large --> */}
				<div className='section section-inner m-image-large'>
					<div className='image'>
						<div
							className='img scrolla-element-anim-1 scroll-animate'
							data-animate='active'
							style={{ backgroundImage: `url(assets/images/${toShow.img})` }}
						></div>
					</div>
				</div>

				{/* <!-- Section Archive --> */}
				<div className='section section-inner m-archive'>
					<div className='container'>
						<div className='row'>
							<div className='col-xs-12 col-sm-12 col-md-12 col-lg-10 offset-1'>
								{/* <!-- content --> */}
								{toShow.content.map((cont, _i) => (
									<div
										key={_i}
										className='post-content'
									>
										<h3>{cont.heading}</h3>
										{cont.paragraphs.map((par, _ix) => (
											<Fragment key={_ix}>
												<p>{par}</p>
												<p>
													<br />
												</p>
											</Fragment>
										))}
									</div>
								))}

								<div className='post-details'>
									<span className='tags-links'>
										<span>Tags:</span>
										{toShow.tags.map((tag, _x) => (
											<a
												key={_x}
												href={`/#/articles?tag=${tag}`}
											>
												{tag}
											</a>
										))}
									</span>
								</div>

								{/* <!-- Comments --> */}
								{/* <div
									className='comments-post scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									
									<div className='section__comments'>
										<div className='m-titles'>
											<div className='m-title align-left'>3 Comments</div>
										</div>

										<ul className='comments'>
											<li className='comment comment-item'>
												<div className='comment comment-box'>
													<img
														src='https://secure.gravatar.com/avatar/aa9d265f1a064dcd6973df722b49c392?s=64&#038;d=mm&#038;r=g'
														className='avatar'
														alt=''
													/>
													<div className='comment-box__body'>
														<div className='content-caption post-content description'>
															<h5 className='comment-box__details'>
																Alex Morrison <span>March 9, 2021</span>
															</h5>
															<p>
																Gallery blocks have two settings: the number of columns, and whether or not images
																should be cropped. The default number of columns is three, and the maximum number of
																columns is eight. Below is a three column gallery at full width, with cropped images.
															</p>
														</div>
													</div>
													<div className='comment-footer'>
														<a
															className='comment-reply-link'
															href='#'
														>
															Reply
														</a>
													</div>
												</div>
											</li>
											<li className='comment comment-item'>
												<div className='comment comment-box'>
													<img
														src='https://secure.gravatar.com/avatar/aa9d265f1a064dcd6973df722b49c392?s=64&#038;d=mm&#038;r=g'
														className='avatar'
														alt=''
													/>
													<div className='comment-box__body'>
														<div className='content-caption post-content description'>
															<h5 className='comment-box__details'>
																John Doe <span>March 9, 2021</span>
															</h5>
															<p>
																Gallery blocks have two settings: the number of columns, and whether or not images
																should be cropped. The default number of columns is three, and the maximum number of
																columns is eight. Below is a three column gallery at full width, with cropped images.
															</p>
														</div>
													</div>
													<div className='comment-footer'>
														<a
															className='comment-reply-link'
															href='#'
														>
															Reply
														</a>
													</div>
												</div>
											</li>
										</ul>

										<div className='form-comment'>
											<div className='comment-respond'>
												<div className='m-titles'>
													<div className='m-title align-left'>Leave a comment</div>
												</div>
												<form
													method='post'
													className='comment-form'
												>
													<div className='group-row'>
														<div className='group full'>
															<input
																type='text'
																name='name'
																placeholder='Full Name'
															/>
														</div>
														<div className='group full'>
															<input
																type='text'
																name='email'
																placeholder='Email Address'
															/>
														</div>
														<div className='group full'>
															<textarea
																className='textarea'
																rows='3'
																placeholder='Comment'
															></textarea>
														</div>
													</div>
													<div className='group-row'>
														<div className='group'>
															<button
																type='submit'
																name='submit'
																id='submit'
																className='btn'
															>
																Submit
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div style={styles.container}>
				<h1>LOADING...</h1>
			</div>
		)
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		textAlign: 'center',
		backgroundColor: '#18181b',
		padding: '20px',
	},

	logo: {
		width: '150px',
		marginBottom: '20px',
	},

	title: {
		fontSize: '2.5rem',
		color: '#333',
		marginBottom: '10px',
	},

	message: {
		fontSize: '1.2rem',
		color: '#666',
		marginBottom: '30px',
	},

	button: {
		fontSize: '1rem',
		color: '#fff',
		backgroundColor: '#0070f3',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		textDecoration: 'none',
	},

	buttonHover: {
		backgroundColor: '#005bb5',
	},
};

export default ArticleComp;
