import { Fragment } from 'react';
import Contact from '../contact';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Contac = () => {
	return (
		<Fragment>
			<Header />
			<Contact />
			<Footer />
		</Fragment>
	);
};

export default Contac;
