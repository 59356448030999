import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		alert('Sending email...');

		emailjs.sendForm('service_3unvc3s', 'template_v0temen', form.current, 'VjaFa636No2ILSTjz').then(
			(result) => {
				alert(`${result.text}: Email Sent`);
				e.target.reset();
			},
			(error) => {
				console.log(error.text);
			}
		);
	};

	return (
		//< !--Wrapper -->
		<div className='wrapper'>
			{/* <!-- Section Started Heading --> */}
			<div className='section section-inner started-heading'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
							{/* <!-- titles --> */}
							<div className='h-titles'>
								<div
									className='h-title splitting-text-anim-2 scroll-animate'
									data-splitting='chars'
									data-animate='active'
								>
									Contact Me
								</div>
								<div className='col-12'>
									<div
										style={{
											backgroundImage: 'url(assets/images/pattern.png)',
											backgroundSize: 'cover',
											height: '10px',
											width: '300px',
											color: 'rgba(255, 255, 255, 0)', // 50% transparency
										}}
									>
										© 2024 Karsten Noko. All rights reserved.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section Image Large --> */}
			<div
				className='section section-inner m-image-large scrolla-element-anim-1 scroll-animate'
				data-animate='active'
			>
				<div className='image'>
					<div
						className='img js-parallax'
						style={{ backgroundImage: 'url(assets/images/contact.jpg)' }}
					></div>
				</div>
			</div>

			{/* <!-- Section Contacts Form --> */}
			<div className='section section-inner m-contacts-form'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-6'>
							{/* <!-- titles --> */}
							<div className='m-titles'>
								<div
									className='m-title scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Get in touch
								</div>
							</div>

							{/* <!-- contact form --> */}
							<div className='contacts-form'>
								<form
									id='cform'
									// method='post'
									onSubmit={sendEmail}
									ref={form}
								>
									<div className='group'>
										<div
											className='value scrolla-element-anim-1 scroll-animate'
											data-animate='active'
										>
											<input
												type='text'
												name='from_name'
												placeholder='Full Name'
											/>
										</div>
									</div>
									<div className='group'>
										<div
											className='value scrolla-element-anim-1 scroll-animate'
											data-animate='active'
										>
											<input
												type='text'
												name='from_email'
												placeholder='Email Address'
											/>
										</div>
									</div>
									<div className='group full'>
										<div
											className='value scrolla-element-anim-1 scroll-animate'
											data-animate='active'
										>
											<textarea
												name='message'
												placeholder='Message'
											></textarea>
										</div>
									</div>
									<div
										className='submit scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<button
											className='btn'
											// onclick={sendEmail}
										>
											Send a Message
										</button>
									</div>
								</form>
								<div
									className='alert-success'
									style={{ display: 'none' }}
								>
									<p>Thanks, your message is sent successfully.</p>
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-6'>
							{/* <!-- titles --> */}
							<div className='m-titles'>
								<h2
									className='m-title scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Contact info
								</h2>
							</div>

							{/* <!-- services --> */}
							<div className='services-items row'>
								<div className='services-col col-xs-12 col-sm-6 col-md-6 col-lg-4'>
									<div
										className='services-item scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='icon'>
											<i
												aria-hidden='true'
												className='fas fa-phone-alt'
											></i>
										</div>
										<div className='name'>Phone:</div>
										<div className='text'>+27 (0) 60 326 4050</div>
									</div>
								</div>

								<div className='services-col col-xs-12 col-sm-6 col-md-6 col-lg-4'>
									<div
										className='services-item scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='icon'>
											<i
												aria-hidden='true'
												className='fas fa-at'
											></i>
										</div>
										<div className='name'>E-mail:</div>
										<div className='text'>karsienoko@gmail.com</div>
									</div>
								</div>

								<div className='services-col col-xs-12 col-sm-6 col-md-6 col-lg-4'>
									<div
										className='services-item scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='icon'>
											<i
												aria-hidden='true'
												className='fab fa-font-awesome-flag'
											></i>
										</div>
										<div className='name'>Location:</div>
										<div className='text'>Johannesburg, South Africa</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
