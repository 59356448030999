import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';

import Home from './components/pages/home';
import About from './components/pages/about';
import Contact from './components/pages/contact';
import Articles from './components/pages/articles';
import Article from './components/pages/article';
import Error from './components/pages/error';

const App = () => {
	useEffect(() => {
		// Function to handle hash changes
		const handleHashChange = () => {
			// Perform an action on hash change, e.g., force reload
			window.location.reload();
		};

		// Add event listener for hash change
		window.addEventListener('hashchange', handleHashChange);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('hashchange', handleHashChange);
		};
	}, []); // Empty dependency array ensures this runs once

	return (
		<HashRouter>
			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/about'
					element={<About />}
				/>
				<Route
					path='/contact'
					element={<Contact />}
				/>
				<Route
					path='/articles'
					element={<Articles />}
				/>
				<Route
					path='/article'
					element={<Article />}
				/>
				<Route
					path='*'
					element={<Error />}
				/>
			</Routes>
		</HashRouter>
	);
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);
