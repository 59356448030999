/* eslint-disable jsx-a11y/anchor-is-valid */
import articles from '../data/articles.json';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ArticlesComponent = () => {
	const [artcls, setArtcls] = useState(articles);
	const [loaded, setLoaded] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const myQuery = searchParams.get('tag');
		console.log(myQuery);

		// filter the articles to find the ones that have the tag
		if (myQuery != null) {
			const filteredArticles = articles.filter((article) => article.tags.includes(myQuery));
			setArtcls(filteredArticles);
		} else setArtcls(articles);
		setLoaded(true);
		setTimeout(() => {}, 5000);
	}, [location]);

	// function to filter articles when tag is pressed
	const filterArticles = async (e) => {
		e.preventDefault();
		let tag = e.target.id;

		if (tag === 'all') {
			navigate('/articles');
			window.location.reload();
			return;
		} else {
			navigate(`/articles?tag=${tag}`);
			window.location.reload();
		}
	};

	return (
		// <!-- Wrapper -->
		loaded ? (
			<div className='wrapper'>
				{/* <!-- Section Started Heading --> */}
				<div className='section section-inner started-heading'>
					<div className='container'>
						<div className='row'>
							<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
								{/* <!-- titles --> */}
								<div className='h-titles'>
									<div
										className='h-subtitle red splitting-text-anim-1 scroll-animate'
										data-splitting='chars'
										data-animate='active'
									>
										My
									</div>
									<h1
										className='h-title splitting-text-anim-2 scroll-animate'
										data-splitting='chars'
										data-animate='active'
									>
										Articles
									</h1>
									<div className='col-12'>
										<div
											style={{
												backgroundImage: 'url(assets/images/pattern.png)',
												backgroundSize: 'cover',
												height: '10px',
												width: '300px',
												color: 'rgba(255, 255, 255, 0)', // 50% transparency
											}}
										>
											© 2024 Karsten Noko. All rights reserved.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Section Archive --> */}
				<div className='section section-inner m-archive'>
					<div className='container'>
						<div className='row'>
							<div className='col-xs-12 col-sm-12 col-md-12 col-lg-8'>
								{/* <!-- archive items --> */}
								<div className='archive-items'>
									{artcls.map((art, _i) => (
										<div
											key={_i}
											className='archive-item scrolla-element-anim-1 scroll-animate'
											data-animate='active'
										>
											<div className='image'>
												<a href={`/#/article?article=${_i}`}>
													<img
														src={`assets/images/${art.img}`}
														alt=''
													/>
												</a>
											</div>
											<div className='desc'>
												<div className='category'>
													Published: <span> {art.publication_date}</span>
												</div>
												<div className='title'>
													<a href={`/#/article?article=${_i}`}>{art.title}</a>
												</div>
												<div className='text'>{art.summary}</div>
												<div className='readmore'>
													<a
														href={`/#/article?article=${_i}`}
														className='btn-link'
													>
														Read more
													</a>
												</div>
											</div>
										</div>
									))}
								</div>

								{/* <!-- pager --> */}
								{/* <div className='pager'>
								<span className='page-numbers current'>1</span>
								<a
									className='page-numbers'
									href='/articles'
								>
									2
								</a>
								<a
									className='page-numbers'
									href='/articles'
								>
									3
								</a>
								<span className='page-numbers dots'>&hellip;</span>
								<a
									className='page-numbers'
									href='/articles'
								>
									9
								</a>
								<a
									className='page-numbers next'
									href='/articles'
								>
									<i className='icon-arrow'></i>
								</a>
							</div> */}
							</div>
							<div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
								{/* <!-- sidebar --> */}
								<div
									className='col__sedebar scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<div className='content-sidebar'>
										<aside className='widget-area'>
											{/* <section className='widget widget_search'>
											<h2 className='widget-title'>Search</h2>
											<form className='search-form'>
												<label>
													<span className='screen-reader-text'>Search for:</span>
													<input
														type='search'
														className='search-field'
														placeholder='Search'
														value=''
													/>
												</label>
												<input
													type='submit'
													className='search-submit'
													value='Search'
												/>
											</form>
										</section> */}

											<section className='widget widget_categories'>
												<h2 className='widget-title'>Tags</h2>
												<ul>
													<li>
														<a
															id='all'
															onClick={filterArticles}
														>
															All
														</a>
													</li>
													<li>
														<a
															onClick={filterArticles}
															id='Africa'
														>
															Africa
														</a>
													</li>
													<li>
														<a
															onClick={filterArticles}
															id='COVID-19'
														>
															COVID-19
														</a>
													</li>
													<li>
														<a
															onClick={filterArticles}
															id='Health'
														>
															Health
														</a>
													</li>
													<li>
														<a
															onClick={filterArticles}
															id='Politics'
														>
															Politics
														</a>
													</li>
												</ul>
											</section>

											{/* <section className='widget widget_recent_entries'>
											<h2 className='widget-title'>Recent Posts</h2>
											<ul>
												<li>
													<a href='#'>3 Ways To Level Up Your Photography Skills of Wildlife</a>
												</li>
												<li>
													<a href='#'>My Style My Choise Lighthouse Long White Jacket</a>
												</li>
												<li>
													<a href='#'>How to Photograph Food without a Tripod</a>
												</li>
												<li>
													<a href='#'>How to Do Panning Photography Nam mollierap ibus</a>
												</li>
											</ul>
										</section> */}

											{/* <section className='widget widget_tag_cloud'>
											<h2 className='widget-title'>Tag Cloud</h2>
											<div className='tagcloud'>
												<a
													href='#'
													className='tag-cloud-link'
												>
													html
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													photo
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													food
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													chat
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													fashion
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													css
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													embeds
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													portfolio
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													personal
												</a>
												<a
													href='#'
													className='tag-cloud-link'
												>
													js
												</a>
											</div>
										</section> */}

											{/* <section className='widget widget_archive'>
											<h2 className='widget-title'>Archives List</h2>
											<ul>
												<li>
													<a href='#'>November 2019</a>&nbsp;(11)
												</li>
												<li>
													<a href='#'>October 2019</a>&nbsp;(1)
												</li>
												<li>
													<a href='#'>January 2020</a>&nbsp;(5)
												</li>
												<li>
													<a href='#'>March 2020</a>&nbsp;(5)
												</li>
												<li>
													<a href='#'>January 2020</a>&nbsp;(6)
												</li>
												<li>
													<a href='#'>March 2020</a>&nbsp;(1)
												</li>
												<li>
													<a href='#'>October 2020</a>&nbsp;(1)
												</li>
												<li>
													<a href='#'>September 2020</a>&nbsp;(2)
												</li>
												<li>
													<a href='#'>August 2020</a>&nbsp;(3)
												</li>
											</ul>
										</section> */}

											{/* <section className='widget widget_recent_comments'>
											<h2 className='widget-title'>Recent Comments</h2>
											<ul>
												<li className='recentcomments'>
													<span className='comment-author-link'>
														<a
															href='#'
															className='url'
														>
															John Doe
														</a>
													</span>{' '}
													on <a href='#'>How to Photograph Food without a Tripod</a>
												</li>
												<li className='recentcomments'>
													<span className='comment-author-link'>
														<a
															href='#'
															className='url'
														>
															John Doe
														</a>
													</span>{' '}
													on <a href='#'>How to Photograph Food without a Tripod</a>
												</li>
												<li className='recentcomments'>
													<span className='comment-author-link'>
														<a
															href='#'
															className='url'
														>
															James Flick
														</a>
													</span>{' '}
													on <a href='#'>My Style My Choise Lighthouse Long White Jacket</a>
												</li>
											</ul>
										</section> */}
										</aside>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div style={styles.container}>
				<h1>LOADING...</h1>
			</div>
		)
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		textAlign: 'center',
		backgroundColor: '#18181b',
		padding: '20px',
	},

	logo: {
		width: '150px',
		marginBottom: '20px',
	},

	title: {
		fontSize: '2.5rem',
		color: '#333',
		marginBottom: '10px',
	},

	message: {
		fontSize: '1.2rem',
		color: '#666',
		marginBottom: '30px',
	},

	button: {
		fontSize: '1rem',
		color: '#fff',
		backgroundColor: '#0070f3',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		textDecoration: 'none',
	},

	buttonHover: {
		backgroundColor: '#005bb5',
	},
};

export default ArticlesComponent;
