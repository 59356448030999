const Error = () => {
	return (
		<div style={styles.container}>
			<img
				src='assets/images/logo.png'
				alt='Logo'
			/>
			<h1>Error Page</h1>
			<p>Oops! Check the address of the page you are looking for</p>
			<a
				href='/'
				passHref
			>
				<button className='btn'>Get Back Home</button>
			</a>
		</div>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		textAlign: 'center',
		backgroundColor: '#18181b',
		padding: '20px',
	},

	logo: {
		width: '150px',
		marginBottom: '20px',
	},

	title: {
		fontSize: '2.5rem',
		color: '#333',
		marginBottom: '10px',
	},

	message: {
		fontSize: '1.2rem',
		color: '#666',
		marginBottom: '30px',
	},

	button: {
		fontSize: '1rem',
		color: '#fff',
		backgroundColor: '#0070f3',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		textDecoration: 'none',
	},

	buttonHover: {
		backgroundColor: '#005bb5',
	},
};

export default Error;
