import { Fragment } from 'react';
import ArticlesComp from '../articles-component';
import Header from '../common/Header';
import Footer from '../common/Footer';

const ArticlesPage = () => {
	return (
		<Fragment>
			<Header />
			<ArticlesComp />
			<Footer />
		</Fragment>
	);
};

export default ArticlesPage;
