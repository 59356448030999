import Header from '../common/Header';
import Footer from '../common/Footer';
import AboutMe from '../aboutme';
import { Fragment } from 'react';

const About = () => {
	return (
		<Fragment>
			<Header />
			<AboutMe />
			<Footer />
		</Fragment>
	);
};

export default About;
