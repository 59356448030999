import { Fragment } from 'react';

const Menu = () => {
	const MenuButtonClick = () => {
		const menuBtn = document.querySelector('.menu-btn');
		if (menuBtn) {
			menuBtn.classList.toggle('active');
		}

		const menuOverlay = document.querySelector('.menu-full-overlay');
		const classesToToggle = ['is-open', 'visible', 'has-scroll', 'animate-active'];

		classesToToggle.forEach((className) => menuOverlay.classList.toggle(className));
	};

	return (
		<Fragment>
			<header className='header'>
				<div className='header__builder'>
					{/* <!-- logo --> */}
					<div className='logo-image'>
						<a href='/'>
							<img
								src='assets/images/logo.png'
								alt='logo'
							/>
						</a>
					</div>

					{/* <!-- menu btn --> */}
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						// href='#'
						id='menu-btn-slctr'
						className='menu-btn full'
						onClick={MenuButtonClick}
					>
						<span></span>
					</a>

					{/* <!-- Menu Full Overlay --> */}
					<div className='menu-full-overlay'>
						<div className='menu-full-container'>
							<div className='container'>
								<div className='row'>
									<div className='col-xs-12 col-sm-12 col-md-12 col-lg-10 offset-1'>
										{/* <!-- menu full --> */}
										<div className='menu-full'>
											<ul className='menu-full'>
												<li className='menu-item'>
													<a
														className='splitting-text-anim-2'
														data-splitting='chars'
														href='/'
													>
														Home
													</a>
												</li>
												<li className='menu-item'>
													<a
														className='splitting-text-anim-2'
														data-splitting='chars'
														href='/#/about'
													>
														About
													</a>
												</li>
												<li className='menu-item'>
													<a
														className='splitting-text-anim-2'
														data-splitting='chars'
														href='/#/articles'
													>
														Articles
													</a>
												</li>
												<li className='menu-item'>
													<a
														className='splitting-text-anim-2'
														data-splitting='chars'
														href='/#/contact'
													>
														Contact
													</a>
												</li>
												{/* <li className='menu-item'>
														<a
															className='splitting-text-anim-2'
															data-splitting='chars'
															href='/blog'
														>
															Blog
														</a>
													</li> */}
											</ul>
											{/* <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
												<div
													style={{
														backgroundImage: 'url(assets/images/pattern.png)',
														color: 'rgba(255, 255, 255, 0)', // 50% transparency
													}}
												>
													© 2024 Karsten Noko. All rights reserved.
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <!-- social --> */}
						{/* <div className='menu-social-as'>
							<a
								href='https://instagram.com/'
								target='blank'
								className='scrolla-element-anim-1'
								title='Instagram'
							>
								Instagram
							</a>
							<a
								href='https://facebook.com/'
								target='blank'
								className='scrolla-element-anim-1'
								title='Facebook'
							>
								Facebook
							</a>
							<a
								href='https://twitter.com/'
								target='blank'
								className='scrolla-element-anim-1'
								title='Twitter'
							>
								Twitter
							</a>
							<a
								href='https://aedin.com/'
								target='blank'
								className='scrolla-element-anim-1'
								title='aedin'
							>
								aedin
							</a>
						</div> */}
					</div>
				</div>
			</header>
		</Fragment>
	);
};

export default Menu;
