const Footer = () => {
	return (
		//  <!-- Footer -->
		<footer className='footer'>
			<div className='footer__builder'>
				<div className='container align-center'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
							<div className='h-titles'>
								<div
									className='h-subtitle scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Address:
								</div>
								<div
									className='h-text scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Johannesburg, South Africa
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
							{/* <!-- titles --> */}
							<div className='h-titles'>
								<div
									className='h-subtitle scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Follow Me:
								</div>
							</div>

							{/* <!-- social --> */}
							<div className='social-links footer-social-links'>
								{/* <a
									target='_blank'
                                    rel="noreferrer"
									href='https://instagram.com/'
									className='scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<i
										aria-hidden='true'
										className='fab fa-instagram'
									></i>
								</a> */}
								<a
									target='_blank'
									rel='noreferrer'
									href='https://www.facebook.com/karsten.noko'
									className='scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<i
										aria-hidden='true'
										className='fab fa-facebook'
									></i>
								</a>
								<a
									target='_blank'
									rel='noreferrer'
									href='https://x.com/KarstenNoko'
									className='scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<i
										aria-hidden='true'
										className='fab fa-twitter'
									></i>
								</a>
								<a
									target='_blank'
									rel='noreferrer'
									href='https://www.linkedin.com/in/karsten-noko-b6a32277/?originalSubdomain=zw'
									className='scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<i
										aria-hidden='true'
										className='fab fa-linkedin-in'
									></i>
								</a>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
							{/* <!-- titles --> */}
							<div className='h-titles'>
								<div
									className='h-subtitle scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Get in touch:
								</div>
								<div
									className='h-text scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<p>
										karsienoko@gmail.com
										<br />
										+27 (0) 60 326 4050
									</p>
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
							<div
								className='copyright-text scrolla-element-anim-1 scroll-animate'
								data-animate='active'
								style={{ color: 'white' }}
							>
								© 2024 Karsten Noko. All rights reserved.
							</div>
							<div
								className='mt-2'
								style={{
									backgroundImage: 'url(assets/images/pattern.png)',
									color: 'rgba(255, 255, 255, 0)', // 50% transparency
								}}
							>
								© 2024 Karsten Noko. All rights reserved.
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
