import { Fragment } from 'react';
import Header from '../common/Header';
import HomeSlider from '../home-slider';

const Home = () => {
	return (
		<Fragment>
			<Header />
			<HomeSlider />
		</Fragment>
	);
};

export default Home;
