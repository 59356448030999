const AboutMe = () => {
	return (
		// <!-- Wrapper -->
		<div className='wrapper'>
			{/* <!-- Section Hero Started --> */}
			<div
				className='section hero-started bottom'
				data-dispimgpath='assets/images/pointilize.jpg'
			>
				{/* <!-- slide --> */}
				<div className='slide'>
					<img
						src='assets/images/about2-1920x1080.jpg'
						className='started-item__image'
						alt=''
					/>
				</div>
				<div
					className='canvas started-items__image'
					data-top='top: 0px;'
					data-top-bottom='top: -200px;'
				></div>

				<div className='container'>
					{/* <!-- titles --> */}
					<div className='titles'>
						<div
							className='title splitting-text-anim-1 scroll-animate'
							data-splitting='chars'
							data-animate='active'
						>
							About Me
						</div>
						<div className='col-12'>
							<div
								style={{
									backgroundImage: 'url(assets/images/pattern.png)',
									backgroundSize: 'cover',
									height: '10px',
									width: '300px',
									color: 'rgba(255, 255, 255, 0)', // 50% transparency
								}}
							>
								© 2024 Karsten Noko. All rights reserved.
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section Description --> */}
			<div className='section section-inner m-description'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 align-left col-lg-3'>
							<div className='m-titles'>
								<div
									className='m-title scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									Background
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-9'>
							<div
								className='description-text scrolla-element-anim-1 scroll-animate'
								data-animate='active'
							>
								<p>
									Karsten Noko is an accomplished international humanitarian aid worker with a career spanning since
									2005. Over the years, Karsten has gained extensive experience in food security, livelihoods, and
									healthcare sectors. <br /> <br />
									Karsten has managed programs and helped develop humanitarian policies in Southern Africa, Nigeria,
									South Sudan, Afghanistan, and Bangladesh. Additionally, Karsten has contributed to humanitarian
									governance, serving for three years on the Board of Directors of a regional chapter of an
									international organization, including two years as Vice President of the Board. Karsten has been
									invited to speak on numerous platforms and symposia.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section Description --> */}
			<div className='section section-inner m-description'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 align-left col-lg-3'>
							<div className='m-titles'>
								<div
									className='m-title scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									What drives me
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-9'>
							<div className='description-list-items'>
								<div
									className='description-list-item scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<div className='desc'>
										<div className='name'>
											{/* <span className='number'>01.</span> */}
											Inspire Through Writing
										</div>
										<p style={{ marginBottom: '0px' }}>
											Share impactful stories and lessons learned to motivate and guide the next generation of
											humanitarian workers.
										</p>
									</div>
								</div>

								<div
									className='description-list-item scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<div className='desc'>
										<div className='name'>
											{/* <span className='number'>02.</span> */}
											Amplifying Voices from the Frontlines
										</div>
										<p style={{ marginBottom: '0px' }}>
											Bringing the experiences of those most affected by crises to the global stage through informed
											advocacy and writing.
										</p>
									</div>
								</div>

								<div
									className='description-list-item scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<div className='desc'>
										<div className='name'>
											{/* <span className='number'>03.</span> */}
											Championing Collaborative Solutions
										</div>
										<p style={{ marginBottom: '0px' }}>
											Building partnerships across sectors to create holistic and sustainable responses to humanitarian
											challenges.
										</p>
									</div>
								</div>

								<div
									className='description-list-item scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									<div className='desc'>
										<div className='name'>
											{/* <span className='number'>04.</span> */}
											Promote Collaborative Action
										</div>
										<p style={{ marginBottom: '0px' }}>
											Foster partnerships across sectors to address complex humanitarian challenges.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section Services --> */}
			<div className='section section-inner m-services'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 align-left col-lg-3'>
							{/* <!-- titles --> */}
							<div className='m-titles'>
								<h2
									className='m-title scrolla-element-anim-1 scroll-animate'
									data-animate='active'
								>
									My services
								</h2>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-9'>
							{/* <!-- services --> */}
							<div className='services-items row'>
								<div className='services-col col-xs-12 col-sm-12 col-md-12 col-lg-12'>
									<div
										className='services-item scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='icon'>
											<i
												aria-hidden='true'
												className='fas fa-feather-alt'
											></i>
											{/* <i className='fa-solid fa-feather-pointed'></i> */}
										</div>
										<div className='name'>Author</div>
										<div className='text'>
											Karsten Noko draws on nearly two decades of experience in international humanitarian work to share
											stories that connect with readers. His writing combines personal experiences with informed
											perspectives on important global topics like food security, healthcare, and humanitarian
											governance. Karsten's approach is not just about providing information but also encouraging
											readers to consider the complex challenges faced by vulnerable communities and the potential
											solutions. Through his work, he aims to promote understanding and inspire thoughtful discussion on
											these critical issues.
										</div>
									</div>
								</div>

								<div className='services-col col-xs-12 col-sm-12 col-md-12 col-lg-12'>
									<div
										className='services-item scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='icon'>
											<i
												aria-hidden='true'
												className='fas fa-people-arrows'
											></i>
										</div>
										<div className='name'>Speaker</div>
										<div className='text'>
											Karsten Noko is a speaker who shares his extensive experience in humanitarian work, providing a
											unique perspective on global challenges. With a reputation for authentic storytelling and
											insightful analysis, he has spoken at many respected platforms, translating complex issues into
											engaging, thought-provoking discussions. Whether speaking to professionals or the general public,
											Karsten aims to inspire action, build understanding, and encourage collaboration. His
											presentations are designed not just to inform, but also to motivate audiences to participate in
											meaningful humanitarian efforts.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section Video Large --> */}
			{/*<div className='section section-inner m-video-large'>
				<div
					className='video scrolla-element-anim-1 scroll-animate'
					data-animate='active'
				>
					<div
						className='img js-parallax'
						style={{ backgroundImage: 'url(assets/images/started-n7.jpg)' }}
					></div>
					{/* <iframe
						className='js-video-iframe'
						data-src='https://www.youtube.com/watch?v=GQgSQVXVisY'
					></iframe> */}
			{/* <iframe
						width='1280'
						height='720'
						src='https://www.youtube.com/embed/GQgSQVXVisY'
						title='Working with MSF | Karsten Noko | Advocacy Representative'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						referrerPolicy='strict-origin-when-cross-origin'
						allowFullScreen
					></iframe>
					<div className='play'></div>
				</div>
			</div> */}

			{/* <!-- Section Partners --> */}
			<div className='section section-inner m-partners'>
				<div className='container'>
					<div className='row'>
						<div className='m-titles'>
							<h2
								className='m-title scrolla-element-anim-1 scroll-animate'
								data-animate='active'
							>
								My Collaborations
							</h2>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
							{/* <!-- partners --> */}
							<div className='partners-box'>
								<div className='partners-items row'>
									<div
										className='partners-col col-xs-12 col-sm-6 col-md-3 col-lg-3 scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='partners-item'>
											<div className='image'>
												<a
													target='_blank'
													rel='noreferrer'
													href='https://www.aljazeera.com/'
												>
													<img
														src='assets/images/brand_logo_03_1.png'
														alt='Aljazeera Logo'
													/>
												</a>
											</div>
										</div>
									</div>
									<div
										className='partners-col col-xs-12 col-sm-6 col-md-3 col-lg-3 scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='partners-item'>
											<div className='image'>
												<a
													target='_blank'
													rel='noreferrer'
													href='https://mg.co.za'
												>
													<img
														src='assets/images/brand_logo_08_1.png'
														alt='Mail&Guardian Logo'
													/>
												</a>
											</div>
										</div>
									</div>
									<div
										className='partners-col col-xs-12 col-sm-6 col-md-3 col-lg-3 scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='partners-item'>
											<div className='image'>
												<a
													target='_blank'
													rel='noreferrer'
													href='https://law.yale.edu'
												>
													<img
														src='assets/images/brand_logo_06_1.png'
														alt='Yale Law Logo'
													/>
												</a>
											</div>
										</div>
									</div>
									<div
										className='partners-col col-xs-12 col-sm-6 col-md-3 col-lg-3 scrolla-element-anim-1 scroll-animate'
										data-animate='active'
									>
										<div className='partners-item'>
											<div className='image'>
												<a
													target='_blank'
													rel='noreferrer'
													href='http://www.nilslandgren.com'
												>
													<img
														src='assets/images/brand_logo_05_1.png'
														alt='Nils Landgren Logo'
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutMe;
