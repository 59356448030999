import Header from '../common/Header';
import Footer from '../common/Footer';
import ArticleComp from '../articleComp';
import { Fragment } from 'react';

const Article = () => {
	return (
		<Fragment>
			<Header />
			<ArticleComp />
			<Footer />
		</Fragment>
	);
};

export default Article;
